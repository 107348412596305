import { trackEvent } from '@99designs/common/utils/platform';

export default function setupSocialShareListCategoriesMenu(
    menuSelector = '[data-social-share-list-categories-menu]',
    menuTriggerSelector = '[data-social-share-list-categories-menu-trigger]'
) {
    const menu = document.querySelector(menuSelector);
    const menuTrigger = document.querySelector(menuTriggerSelector);

    if (!menu || !menuTrigger) {
        return;
    }

    initClickTracking(menu);

    menuTrigger.addEventListener('click', event => {
        if (isMenuVisible(menu)) {
            hideMenu(menu);
        } else {
            showMenu(menu);
        }

        event.stopPropagation();
    });

    menu.addEventListener('click', event => {
        event.stopPropagation();
    });

    document.addEventListener('click', () => {
        hideMenu(menu);
    });
}

function initClickTracking(menu: Element) {
    const categoryMenuSeeAllLink = menu.querySelector('[data-social-share-categories-menu-see-all-link]');
    if (categoryMenuSeeAllLink) {
        categoryMenuSeeAllLink.addEventListener('click', () => {
            trackEvent('Clicked See All Link On Social Share Categories Menu')
        });
    }

    const categoryLinks = menu.querySelectorAll('[data-social-share-categories-menu-link]');
    Array.from(categoryLinks).forEach((link: Element) => {
        link.addEventListener('click', () => {
            trackEvent('Clicked Category Link On Social Share Categories Menu', {
                category_key: link.getAttribute('data-social-share-categories-menu-category-key')
            });
        });
    });
}

function isMenuVisible(menu: Element) {
    return menu.getAttribute('data-social-share-list-categories-menu-active');
}

function hideMenu(menu: Element) {
    menu.removeAttribute('data-social-share-list-categories-menu-active');
}

function showMenu(menu: Element) {
    menu.setAttribute('data-social-share-list-categories-menu-active', 'true');
}
