import trackEvent from '@99designs/common/utils/platform/trackEvent';
import hoverintent from 'hoverintent';

export default function trackDesignAttributionHover() {
    Array.from(document.querySelectorAll('[data-track-hover]')).forEach((element) => {
        hoverintent(
            element,
            () => {
                trackEvent('Hovered on Blog attribution');
            },
            () => {}
        );
    });
}
