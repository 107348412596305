import '@99designs/common/jquery-plugins/promo-bar';

import trackLink from '@99designs/common/utils/platform/trackLink';
import trackEvent from '@99designs/common/utils/platform/trackEvent';

export default function setupPromoBar(promoBarSelector = '[data-promo-bar]') {
    $(promoBarSelector).promoBar({
        onShow: (elem) => {
            trackEvent('Viewed Notice Bar', { notice_bar_name: elem.name });
            trackLink(
                elem.$el.find('a').not('[data-promo-bar-dismiss]').get(),
                'Clicked Notice Bar Link',
                {
                    notice_bar_name: elem.name,
                }
            );
        },
    });
}
