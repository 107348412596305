import debounce from '../utils/debounce';
import setupSocialShareListCategoriesMenu from './setupSocialShareListCategoriesMenu';
import trackLink from '@99designs/common/utils/platform/trackLink';

function setupSocialShareList() {
    var $window = $(window);
    var $siteWrapper = $('[data-site-wrapper]');
    var windowWidth = $window.width();
    var mediumBreakpoint = 600;
    var normalBreakpoint = 900;
    var largeBreakpoint = 1300;
    var isLarge;
    var isNormal;
    var isMedium; // eslint-disable-line no-unused-vars
    var resizeTasks = [];

    var $article = $('[data-article]');
    var $articleShare = $('[data-article-share]');
    var $articleShareList = $articleShare.find('[data-social-share-list]');
    var $articleShareLink = $articleShare.find('[data-social-share-button]');
    var $articleColor = $articleShare.attr('data-article-color');
    var articleBodySelector = '.article__body';
    var $articleBody = $(articleBodySelector);
    var articleShareStickyTop = 150;
    var articleShareDefaultTop,
        articleShareStickyMin,
        articleScrolled,
        articleShareStickyMax,
        newSharingTop;

    function updateArticleShareHighlighting() {
        if ($articleBody.offset().top < $window.scrollTop() && windowWidth >= largeBreakpoint) {
            $articleShare.css('color', $articleColor);
            $articleShare.attr('data-state', 'highlighted');
        } else {
            $articleShare.removeAttr('data-state', 'highlighted');
        }
    }

    function articleStickyShare() {
        if (isNormal || isLarge) {
            articleScrolled = Math.ceil($window.scrollTop());
            if (articleScrolled < 0) {
                articleScrolled = 0;
            }

            articleShareStickyMax = $article.height() - $articleShare.height();

            if (!articleScrolled || articleScrolled <= articleShareStickyMin) {
                // put in default pos
                newSharingTop = articleShareDefaultTop - articleScrolled;
            } else if (articleScrolled >= articleShareStickyMax) {
                // end the stickiness and move the scroll
                newSharingTop = articleShareStickyTop - (articleScrolled - articleShareStickyMax);
            } else {
                // make sticky
                newSharingTop = articleShareStickyTop;
            }

            updateArticleShareHighlighting();

            $articleShare.css({ top: newSharingTop + 'px' });
        }
    }

    if ($articleShare.length) {
        articleShareDefaultTop = $articleShare.position().top;
        articleShareStickyMin = articleShareDefaultTop - articleShareStickyTop;

        $window.scroll(articleStickyShare);
    }

    function handleResizeForSocialSharing() {
        if ($articleShare.length) {
            if (windowWidth >= largeBreakpoint) {
                if ($articleShare.parent(articleBodySelector).length) {
                    // screen size has increased and the shares are still in the DOM position for smaller screens
                    $articleShare.detach().appendTo($siteWrapper);
                    articleShareDefaultTop = $articleShare.position().top;
                    articleStickyShare();
                }

                updateArticleShareHighlighting();

                $articleShareLink.addClass('social-share-button--flyout');

                $articleShareList
                    .addClass('social-share-list--vertical')
                    .removeClass('social-share-list--horizontal');
            } else if (!$articleShare.parent(articleBodySelector).length) {
                // screen size is small and the shares are still in the DOM position for larger screens
                $articleShare.css('top', '').detach().appendTo(articleBodySelector);

                $articleShareLink
                    .removeClass('social-share-button--chameleon')
                    .removeClass('social-share-button--flyout');
                $articleShareList
                    .addClass('social-share-list--horizontal')
                    .removeClass('social-share-list--vertical');

                articleStickyShare();
            }
        }
    }

    resizeTasks.push(handleResizeForSocialSharing);

    // Track share links
    $('[data-social-share-button]').each(function () {
        trackLink(this, 'Clicked Social Share Button', {
            share_via: this.getAttribute('data-social-share-button-type').toLowerCase(),
            share_subject_type: 'blog post',
        });
    });

    function onWindowResize() {
        // Check breakpoints
        windowWidth = $window.width();
        isMedium = windowWidth >= mediumBreakpoint && windowWidth < normalBreakpoint;
        isNormal = windowWidth >= normalBreakpoint && windowWidth < largeBreakpoint;
        isLarge = windowWidth >= largeBreakpoint;

        $.each(resizeTasks, function (i, fn) {
            if (typeof fn === 'function') {
                fn();
            }
        });
    }

    $window.on('resize', debounce(onWindowResize, 100));
    // This executes after the above event handler (jquery guarantees this)
    // We do this so the social share widget can load in after it has been resized
    // and placed into the correct DOM position.
    // We do this to reduce CLS (cumulative layout shift) and avoid the social share appearing briefly
    // on mobile before disappearing.
    $window.on(
        'resize',
        debounce(function () {
            $articleShare.addClass('article-share--loaded');
        }, 100)
    );
    $window.trigger('resize');

    $window.on('load', onWindowResize);

    // Initialise Social Share List Categories Menu
    setupSocialShareListCategoriesMenu();
}

export default setupSocialShareList;
