import 'babel-polyfill';

import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';
import 'whatwg-fetch';

import trackLink from '@99designs/common/utils/platform/trackLink';
import DetachedDropdown from '@99designs/common/jquery-plugins/detached-dropdown';
import trackBlogCtaLinks from 'utils/trackBlogCtaLinks';
import trackDesignAttributionHover from 'utils/trackDesignAttributionHover';
import trackDesignAttributionClick from 'utils/trackDesignAttributionClick';
import setupPromoBar from 'common/setupPromoBar';
import StickyBar from './components/StickyBar';
import setupSocialShareList from './common/setupSocialShareList';

require('lib/jquery.fitvids');
require('lib/jquery.serializeObject');

// Utility data-attribute from brand-container to prevent links following normal
// clicks when JS behaviour is involved. Middle click, new tab, etc will still work
// https://gitlab.com/vistaprint-org/99designs/frontend-components/-/blob/25923cb8e71e961404da6c61742c8cc649a041d1/packages/brand-container/index.js#L61-L69
$('[data-no-link]').on('click', function (e) {
    // Middle click, cmd click, and ctrl click should open
    // links in a new tab as normal.
    if (e.which > 1 || e.metaKey) {
        return true;
    } else {
        e.preventDefault();
    }
});

(function ($) {
    $(document).ready(function () {
        var $body = $('body');
        var $htmlBody = $('html, body');

        // Initialise Noticebar
        setupPromoBar();

        // Initialise tracking
        trackBlogCtaLinks();
        trackDesignAttributionHover();
        trackDesignAttributionClick();

        // Initialise Social Share List
        setupSocialShareList();

        // Initialise StickyBar
        const stickyBar = document.querySelector('[data-sticky-bar]');
        if (stickyBar) {
            new StickyBar(stickyBar);
        }

        /***************************
         *
         *	EXTERNAL LINKS
         *
         ***************************/

        $('a[rel="external"]').attr('target', '_blank');

        /***************************
         *
         *	FITVIDS
         *
         ***************************/

        $body.fitVids();

        /***************************
         *
         *	SCROLLTO
         *
         ***************************/
        $body.on('click', 'a.scroll-to', function (e) {
            var sel = $(this).attr('href');
            var $target = $(sel);
            if ($target.length) {
                e.preventDefault();
                $htmlBody.animate({ scrollTop: $target.offset().top - 30 }, 600);
            }
        });

        /***************************
         *
         *	FORMS
         *
         ***************************/

        $body.on('change', '.field--checkable-set input', function () {
            var $set = $(this).closest('.field--checkable-set');
            $set.find('input:checked').closest('.field--checkable').addClass('checked');
            $set.find('input:not(:checked)').closest('.field--checkable').removeClass('checked');
        });

        /***************************
         *
         *	SEARCH
         *
         ***************************/

        var $searchOpen = $('[data-search-form-open-trigger]');
        var $searchClose = $('[data-search-form-close-trigger]');
        var $searchForm = $('[data-search-form]');

        $searchOpen.click(function () {
            $searchForm.fadeIn('fast', function () {
                $searchForm.find('input:first').focus();
            });
        });

        $searchClose.click(function () {
            $searchForm.fadeOut('fast');
        });

        /***************************
         *
         *	MOBILE MENU
         *
         ***************************/

        var $mobileMenu = $('[data-sidebar]');
        var $siteWrap = $('[data-site-wrapper]');
        var $mobileMenuToggle = $('[data-oc-trigger]');

        $mobileMenuToggle.click(function () {
            $mobileMenu.toggleClass('active');
            $siteWrap.toggleClass('active');
        });

        /***************************
         *
         *	SUBSCRIBE LINK
         *
         ***************************/
        var $subscribeLink = $('a[href="#subscribe"]');
        if (!$('#subscribe').length && $subscribeLink.length) {
            $subscribeLink
                .removeClass('scroll-to')
                .attr('href', window.global_vars.home + '#subscribe');
        }

        /***************************
         *
         *	PLACEHOLDERS
         *
         ***************************/
        function emulatePlaceholders() {
            if (Modernizr && Modernizr.input && !Modernizr.input.placeholder) {
                $('form').each(function () {
                    var $form = $(this);
                    var $placeholderFields = $form.find(
                        'input[placeholder], textarea[placeholder]'
                    );

                    if ($placeholderFields.length) {
                        $placeholderFields
                            .focus(function () {
                                var $input = $(this);
                                if ($input.val() === $input.attr('placeholder')) {
                                    $input.val('');
                                    $input.removeClass('placeholder');
                                }
                            })
                            .blur(function () {
                                var $input = $(this);
                                if (
                                    $input.val() === '' ||
                                    $input.val() === $input.attr('placeholder')
                                ) {
                                    $input.addClass('placeholder');
                                    $input.val($input.attr('placeholder'));
                                }
                            })
                            .blur();

                        $form.submit(function () {
                            $placeholderFields.each(function () {
                                var $input = $(this);
                                if ($input.val() === $input.attr('placeholder')) {
                                    $input.val('');
                                }
                            });
                        });

                        $placeholderFields.each(function () {
                            var $input = $(this);
                            if (
                                $input.val() === '' ||
                                $input.val() === $input.attr('placeholder')
                            ) {
                                $input.addClass('placeholder');
                                $input.val($input.attr('placeholder'));
                            }
                        });
                    }
                });
            }
        }
        emulatePlaceholders();

        /***************************
         *
         *	GRAVITY FORMS SETUP
         *
         ***************************/

        function gravityformsSetup() {
            var $forms = $('.gform_wrapper form');

            if ($forms.length > 0) {
                $forms.each(function () {
                    var $form = $(this);
                    $form.attr('novalidate', 'novalidate');
                });
            }
        }

        $(document).bind('gform_post_render', function () {
            gravityformsSetup();
        });

        /*
		 $(document).bind('gform_page_loaded', function(event, form_id, current_page){
		    // code to be trigger when next/previous page is loaded
		 });
		 */

        /***************************
         *
         *	ACCORDION
         *
         ***************************/

        function initAccordions($accordions) {
            $accordions.each(function (index, accordion) {
                var activeAttr = 'expanded';
                var $header = $(accordion).find('[data-accordion-header]');

                $header.on('click', function (e) {
                    e.preventDefault();
                    var $accordion = $header.parent();

                    if ($accordion.attr(activeAttr) !== undefined) {
                        $accordion.removeAttr(activeAttr);
                    } else {
                        $accordion.attr(activeAttr, '');
                    }
                });
            });
        }

        initAccordions($('[data-accordion]'));

        DetachedDropdown.createAll('[data-detached-dropdown]', {
            spacing: 15,
        });

        /***************************
         *
         *	LINK TRACKING
         *
         ***************************/

        function enableLinkTracking($links, event, data) {
            $links.each(function (index, link) {
                trackLink(link, event, data);
            });
        }

        enableLinkTracking(
            $('[data-related-content-link]'),
            'Clicked Related Content Link On Blog',
            {
                link_type: 'footer',
            }
        );
    });
})(jQuery.noConflict());
